import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
// import HeroImage from '../images/hero/present.jpg'
import ScrollIndicator from '../components/Ui/ScrollIndicator'
import Footer from '../components/Footer'
import ContentBlocksIntroduction from '../components/ContentBlocks/ContentBlocksIntroduction'
import ContentBlocksLead from '../components/ContentBlocks/ContentBlocksLead'
import ContentBlocksDefault from '../components/ContentBlocks/ContentBlocksDefault'
import ContentBlocksCallToAction from '../components/ContentBlocks/ContentBlocksCallToAction'

const footerLinks = ['past', 'future']

const PresentPage = ({ location, data }) => {
  const page = data.prismicPresent.data

  const sources = [
    data.mobileHeroImage.childImageSharp.fluid,
    {
      ...data.heroImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <ScrollIndicator />
      <Hero title="Present" preTitle="Okains Bay" image={sources} scrollLink />
      <div id="discover" className="pt-48 pb-32">
        <div className="mb-56">
          <ContentBlocksLead text={page.lead_text} image={page.lead_image} />
        </div>
        <div className="mb-56">
          <ContentBlocksIntroduction
            title={page.lead_secondary_title}
            text={page.lead_secondary_text}
          />
        </div>
        <div className="mb-32">
          <ContentBlocksDefault
            text={page.main_primary_text}
            image={page.main_primary_image}
          />
        </div>
        <div className="mb-32">
          <ContentBlocksDefault
            reversed
            text={page.main_secondary_text}
            image={page.main_secondary_image}
          />
        </div>
        <div className="mb-32">
          <ContentBlocksDefault
            title={page.main_tertiary_title}
            text={page.main_tertiary_text}
            image={page.main_tertiary_image}
          />
        </div>
        <div className="mb-32">
          <ContentBlocksCallToAction
            title={page.cta_primary_title}
            text={page.cta_primary_text}
            image={page.cta_primary_image}
            alt={page.cta_primary_image.alt}
            link={page.cta_primary_link.uid}
            linkText={page.cta_primary_link_text}
          />
        </div>
        <ContentBlocksCallToAction
          reversed
          title={page.cta_secondary_title}
          text={page.cta_secondary_text}
          image={page.cta_secondary_image}
          alt={page.cta_secondary_image.alt}
          link={page.cta_secondary_link.uid}
          linkText={page.cta_secondary_link_text}
        />
      </div>
      <Footer links={footerLinks} />
    </Layout>
  )
}

PresentPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pagePresentQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/present.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeroImage: file(relativePath: { eq: "hero/mobile/present.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicPresent {
      data {
        meta_title
        meta_description
        cta_primary_image {
          url
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2880, maxHeight: 2080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cta_primary_text {
          html
          text
        }
        cta_primary_title
        cta_secondary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2692, maxHeight: 1642) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cta_primary_link {
          uid
        }
        cta_primary_link_text
        cta_secondary_text {
          html
          text
        }
        cta_secondary_title
        lead_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1462, maxHeight: 1072) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cta_secondary_link {
          uid
        }
        cta_secondary_link_text
        lead_secondary_text {
          html
          text
        }
        lead_secondary_title
        lead_text {
          html
          text
        }
        main_primary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1122) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        main_primary_text {
          html
          text
        }
        main_tertiary_title
        main_tertiary_text {
          html
          text
        }
        main_tertiary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1122) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        main_secondary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1122) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        main_secondary_text {
          html
          text
        }
      }
    }
  }
`

export default PresentPage
